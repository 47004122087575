export const geocode = (location) => {
    let body = {'location' : {'lat' : location.coords.latitude, 'lng' :  location.coords.longitude}};
    const options = {
        method: "POST",
        body: JSON.stringify(body),
        headers : {
            "Content-Type" : 'application/json'
        }
    }
    return manageFetch(process.env.MIX_REACT_APP_URL + "/api/geocode", options);
}

export const formErrorCallback = (errors, force = false) => {
    document.querySelectorAll('.is-invalid').forEach(element => {
        element.classList.remove('is-invalid');
    })
    document.querySelectorAll('.invalid-feedback').forEach(element => {
        element.remove();
    })
    Object.keys(errors).forEach((element) => {
        if (element ===  "code" && !force) return;
        let inputElement = document.querySelector('[name="'+element+'"]');
        if (inputElement) {
            inputElement.classList.add('is-invalid');
            let div =  document.createElement("div");
            div.className = "invalid-feedback";
            div.innerHTML = errors[element];
            inputElement.parentNode.insertBefore(div,inputElement.nextSibling);
        }
    })
}

export const removeErrors = (errors) => {
    document.querySelectorAll('.is-invalid').forEach(element => {
        element.classList.remove('is-invalid');
    })
    document.querySelectorAll('.invalid-feedback').forEach(element => {
        element.remove();
    })
}

export const manageFetch = async (url, options) => {
    try {
        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (response.ok && response.status >= 200 && response.status < 300) {
            return responseJson;
        } else {
            responseJson.code = response.status;
            throw responseJson;
        }
    } catch (e) {
        return console.log(e)
    }
}
