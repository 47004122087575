import React from "react";
import {Polyline} from "@react-google-maps/api";

const TransitPolyline = (props) => {

    const options = props.type === 'JNY' ? {
        fillColor:  props.color,
        strokeWeight: props.border ? 10 : 7,
        strokeColor: props.border ? props.color : props.backgroundColor,
        strokeOpacity: 1,
        zIndex: -1,
    } : {
        fillColor: "#FFFFFF",
        strokeWeight: 10,
        strokeColor: "#999999",
        strokeOpacity: 0,
        icons: [{
            icon:  {
                path: 'M 0,1 0,1',
                strokeOpacity: 1,
                scale:7
            },
            offset: '5',
            repeat: '10px'
        }],
        zIndex: 0,
    }

    return <Polyline
                key={props.idx}
                path={google.maps.geometry.encoding.decodePath(props.path)}
                options={options}
            />
}

export default TransitPolyline
