import React from "react"

import {Button, Spinner} from "react-bootstrap";
import {FiArrowLeft,FiArrowRight, FiArrowUp, FiArrowDown} from "react-icons/all";

const NocButton = (props) => {

    const arrows = {
        'left' : <FiArrowLeft className="button-right"/>,
        'right' : <FiArrowRight className="button-right"/>,
        'up' : <FiArrowUp className="button-right"/>,
        'down' : <FiArrowDown className="button-right"/>
    }

    return(
        <Button onClick={props.handleClick ?? null}
                variant={props.variant ?? null}
                disabled={props.disabled ?? false}
                active={!props.disabled ?? true}
                className={'button-submit btn-lg rounded-pill '+ (props.className ?? '')}
                type={props.type ?? 'button'}>
            {
                (props.status === 'pending') &&
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-2'
                />
            }
            {props.title}
            {props.icon ?? arrows[props.arrow]}
        </Button>
    )
}

export default NocButton;
