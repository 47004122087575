import React, {useEffect, useState} from "react"
import {Form, Button, Spinner} from "react-bootstrap"
import {FaArrowRight} from "react-icons/all";
import {useNavigate, useParams} from "react-router-dom";

import { useDispatch } from "react-redux"
import DirectionThunks from "../../../../features/direction/direction.thunks"
import {setDirectionResult, setDirectionMarkers} from "../../../../features/map/map.slice";
import InputAddress from "../InputAddress"

import "../../journey.css"
import {formToMarkers} from "../../../../utils/googleMap";
import NocButton from "../../../../commons/NocButton";
import InputDate from "../InputDate";
import InputHafasAddress from "../InputHafasAddress";

const FormDirection = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    let [form, setForm] = useState({from : {}, to : {}})

    let btnDisabled =
        Object.keys(form).filter((k) => form[k].crd !== undefined ).length === 2 &&
        props.status !== 'pending'

    const handleChange = (name,value) => setForm({...form,[name]:value})
    const handleChangeDate = (values) => setForm({...form,...values})

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(DirectionThunks.post(JSON.stringify(form)))
    }

    useEffect(() => {
        if (form.from.geometry !== undefined || form.to.geometry !== undefined ) {
            dispatch(setDirectionMarkers(formToMarkers(form)))
        }
    },[form]);

    useEffect(() => {
        if (props.tab === 'direction' ) {
            dispatch(setDirectionMarkers(formToMarkers(form)))
        }
    },[props.tab])

    return  (
            <div className={'form py-5 px-4  '+ props.className }>
                <h3 className="mb-4"><span className='fw-bolder'>Saisis</span> ta destination...</h3>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="inputDate">
                        <InputDate
                            showOutFrwd={true}
                            handleChange={handleChangeDate}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inputDeparture">
                        <InputHafasAddress
                            value={(form.from.name) || ''}
                            placeholder='Départ'
                            name="from"
                            handleChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inputDestination">
                        <InputHafasAddress
                            value={(form.to.name) || ''}
                            placeholder='Destination'
                            name="to"
                            handleChange={handleChange}
                        />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <NocButton
                            disabled={!btnDisabled}
                            active={btnDisabled}
                            type={'submit'}
                            status={props.status}
                            arrow='right'
                            title={<b>Trouve mon itinéraire!</b>}
                        />
                    </div>
                </Form>
            </div>
    )
}

export default FormDirection
