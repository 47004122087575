import {manageFetch} from "../../utils/utils";
import {createAsyncThunk} from '@reduxjs/toolkit'

export async function request(method, id = null) {
    const options = {
        method: method,
    }
    const url = process.env.MIX_REACT_APP_URL + "/api/lines"+ (id ? "/"+id : "");
    return manageFetch(url, options)
}

const LinesThunks = {
    getAll : createAsyncThunk('lines/getAll',
        async (thunkAPI)=> {
            const response = await request('get');
            return {response};
    }),
    getById : createAsyncThunk('lines/getById',
        async (id, thunkAPI)=> {
            const response = await request('get',id);
            return {response};
    })
}
export default LinesThunks;
