import React,{useState, useEffect} from "react"
import {Container, Row, Col, Stack, Button} from "react-bootstrap"
import {useSelector, useDispatch} from "react-redux"
import {FaArrowLeft, FaRegClock, FaMapMarkerAlt, FaWalking, FaMap, FaList} from "react-icons/fa"

import FormDirection from "./FormDirection"
import Step from "./Step"
import {removeDirection} from "../../../../features/direction/direction.slice"
import {
    removeAll,
    setDepartureResult,
    setDirectionMarkers,
    setDirectionResult
} from "../../../../features/map/map.slice";
import {formToMarkers} from "../../../../utils/googleMap";
import {FaMapMarkedAlt} from "react-icons/all";
import {removeDeparture} from "../../../../features/departure/departure.slice";

const Direction = (props) => {
    const dispatch = useDispatch()
    const {result, status} = useSelector(state => state.direction)
    const [page, setPage] = useState(0)
    const directionMarkers = useSelector(state => state.map.directionMarkers);

    const handleBack = () => {
        setPage(0)
        props.handleShowMap(false);
        dispatch(removeDirection())
    }
    const handleSetPage = (inc) => {
        setPage(page+inc)
    }

    useEffect(() => {
        if (props.isIframe && result !== null) {
            localStorage.setItem('init_direction', JSON.stringify({result : result, markers : directionMarkers}));
            window.top.location.replace(process.env.MIX_REACT_APP_REDIRECT);
        } else dispatch(setDirectionResult(result !== null && result.status !== 'ZERO_RESULTS' ? result.journeys[page] : null));
    },[result, page])

    useEffect(() => {
        if (props.tab === 'direction') {
            dispatch(setDirectionResult(result !== null && result.status !== 'ZERO_RESULTS' ? result.journeys[page] : null))
        }
    },[props.tab])

    return  (
            <>
                <FormDirection isIframe={props.isIframe} tab={props.tab} className={(result !== null && !props.isIframe) && 'd-none'} status={status}/>
                {result !== null && !props.isIframe &&
                    <>
                        <Container fluid='lg' className="return">
                            <Row>
                                <Col xs={2}>
                                    <button onClick={handleBack}
                                            className='btn btn-sm btn-default  d-flex text-white'>
                                        <FaArrowLeft/>
                                    </button>
                                </Col>
                                <Col xs={8} className='ps-4 d-flex align-items-center'>
                                    <h5 className="text-center mb-0">Résultats</h5>
                                </Col>
                                <Col xs={2} className='ps-4 d-flex align-items-center d-md-none'>
                                    <button onClick={ () => props.handleShowMap(!props.showMap)}
                                            className='btn btn-sm btn-default  d-flex text-white'>
                                        {!props.showMap ? <FaMapMarkedAlt/> : <FaList/> }
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                        {result.status !== 'ZERO_RESULTS' ?
                            <Container fluid='lg' className={(props.showMap && 'd-none') + ' itinerary py-4'}>
                                <Row>
                                    <Col>
                                        <h3>Itinéraire</h3>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col xs={3} lg={4}>
                                        <b>Depuis</b>
                                    </Col>
                                    <Col>
                                        <FaMapMarkerAlt/> {result.journeys[page].from}<br/>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-3 py-2">
                                    <Col xs={3} lg={4}>
                                        <b>Vers</b>
                                    </Col>
                                    <Col>
                                        <FaMapMarkerAlt/> {result.journeys[page].to}
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-3 py-3">
                                    <Col xs={4}>
                                        <b>{result.journeys[page].start}</b>
                                        <p className='mb-0'><FaRegClock/> {result.journeys[page].duration} minutes
                                        </p>
                                    </Col>
                                    <Col xs={6}>
                                        {result.journeys[page].from}<br/>
                                        {result.journeys[page].to}
                                    </Col>
                                    <Col xs={2}>
                                        <b>{result.journeys[page].end}</b>
                                    </Col>
                                </Row>
                                {
                                    (result.journeys[page].steps[0].type !== "JNY") &&
                                        <Row className="border-bottom py-3">
                                            <Col xs={4}>
                                                <b>{result.journeys[page].start}</b>
                                            </Col>
                                            <Col xs={6}>
                                                <b>{result.journeys[page].from}</b>
                                            </Col>
                                        </Row>
                                }
                                {
                                    result.journeys[page].steps.map((step, idx) =>
                                        <Step {...step} key={'step_' + idx}/>
                                    )
                                }
                                {
                                    (result.journeys[page].steps[result.journeys[page].steps.length-1].type !== "JNY") &&
                                        <Row className="border-bottom py-3">
                                            <Col xs={4}>
                                                <b>{result.journeys[page].end}</b>
                                            </Col>
                                            <Col xs={6}>
                                                <b>{result.journeys[page].to}</b>
                                            </Col>
                                        </Row>
                                }
                                <Row className="border-top border-3 py-3">
                                    <Col xs={6}>
                                        {(page >= 1) &&
                                            <a className="link" onClick={() => handleSetPage(-1)}><b>Départs précédents</b></a>
                                        }
                                    </Col>
                                    <Col xs={6} style={{textAlign: "right"}}>
                                        {(result.journeys.length - 1 > page) &&
                                            <a className="link" onClick={() => handleSetPage(1)}><b>Départs suivants</b></a>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        :
                            <Container className="itinerary py-4">
                                <>Aucun résultat</>
                            </Container>
                        }
                    </>
                }
            </>
    )
}

export default Direction
