import React, {useEffect, useRef, useState} from "react";
import {Button, InputGroup, Form, ButtonGroup, ToggleButton} from "react-bootstrap";
import moment from "moment";
import TimePicker from 'react-weblineindia-time-picker'


const InputDate = (props) => {
    //outDate: "20220804"  // outTime: "093200" // outFrwd: true = depart / arrive false

    const outFrwdTitles = ['Départ', 'Arrivée'];
    const days = {5 : 'Vendredi',6 : 'Samedi',7 : 'Dimanche'};
    const hours = {
        5 : [23],
        6 : [0,1,2,3,4,23],
        7 : [0,1,2,3,4]
    }
    const [outFrwd, setOutFrwd] = useState(0);

    let today = moment().isoWeekday();
    let defaultDay = days[today] ? today : 5;

    const [selectedDay, setSelectedDay] = useState(defaultDay)

    let defaultTime = moment().isoWeekday(selectedDay).hours(hours[selectedDay][0]).minutes(0).toDate()
    const [time, setTime] = useState(defaultTime);

    useEffect(() => {
        let tmpTime = hours[selectedDay].includes(time.getHours()) ?
            time :
            moment().isoWeekday(selectedDay).hours(hours[selectedDay][0]).minutes(0).toDate();
        setTime(tmpTime)
    }, [selectedDay])

    useEffect(() => {
        props.handleChange(
            {
                'outTime' : moment(time).format('HHmm00'),
                'outFrwd' : !+outFrwd,
                'outDate' : moment().isoWeekday(selectedDay).format('YYYYMMDD')
            }
        )
    }, [outFrwd,selectedDay,time])

    return (
        <div className={'d-flex w-100 align-items-center justify-content-center flex-wrap'}>
            {props.showOutFrwd &&
                <ButtonGroup className='me-2 flex-fill'>
                    {outFrwdTitles.map((title, idx) => (
                        <ToggleButton
                            className={'border-0 form-control-lg  noc-outfrwd-button rounded-pill rounded-'+(idx % 2 ? 'start' : 'end')}
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={'outline-secondary'}
                            name="radio"
                            value={idx}
                            checked={+outFrwd === +idx}
                            onChange={(e) => setOutFrwd(e.currentTarget.value)}
                        >
                            {title}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            }
            <Form.Select
                aria-label=""
                name="outDate"
                className={'rounded-pill   form-control-lg ps-3 w-auto border-0 noc-select-day ' + (props.showOutFrwd && 'flex-fill') }
                value={selectedDay} onChange={(e) => setSelectedDay(+e.target.value)}>
                {Object.keys(days).map((day, idx) => (
                    <option key={idx} value={day}>
                        {days[day]}
                    </option>
                ))}
            </Form.Select>
            <div className={'ms-2 ' + (props.showOutFrwd && 'mt-3 mt-md-0')}>
                <TimePicker
                    onChange={
                        (e) => {
                            if (selectedDay === 5 && e.value.getHours() === 0) {
                                setTime(e.value)
                                setSelectedDay(6)
                            } else if (selectedDay === 6 && e.value.getHours() === 0  && time.getHours() === 23) {
                                setTime(e.value)
                                setSelectedDay(7)
                            } else if (selectedDay === 6 && e.value.getHours() === 23 && time.getHours() === 0) {
                                setTime(e.value)
                                setSelectedDay(5)
                            } else if (selectedDay === 7 && e.value.getHours() === 23 && time.getHours() === 0) {
                                setTime(e.value)
                                setSelectedDay(6)
                            } else if (selectedDay === 6 && e.value.getHours() === 5  && time.getHours() === 4) {
                                setTime(e.value.setHours(23))
                            } else if (selectedDay === 6 && e.value.getHours() === 22 && time.getHours() === 23) {
                                setTime(e.value.setHours(4))
                            }
                            if (hours[selectedDay].includes(e.value.getHours())) setTime(e.value)
                        }
                    }
                    value={time}
                    locale='fr'
                    timeOnly={true}
                    hourFormat={'24'}
                    manualInput={true}
                    id="time"
                    inline={true}
                    stepMinute={5}
                    placeholder="Time picker"
                />
            </div>
        </div>
    )
}
export default InputDate
