import React, {useCallback, useRef, useState} from "react";
import { Autocomplete } from '@react-google-maps/api';
import {Button, InputGroup, Form} from "react-bootstrap";
import {BiCurrentLocation} from "react-icons/all";
import {geocode, manageFetch} from "../../../utils/utils";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import iconBus from "../../../../../../public/images/NOC_icon_bus.svg"
import iconAddress from "../../../../../../public/images/NOC_icon_address.svg"

const InputHafasAddress = (props) => {

    const [timer, setTimer] = useState(null)
    const [addresses, setAddresses] = useState([]);

    async function getAddress (body)  {
        const options = {
            method: 'post',
            body: body,
            headers : {
                "Content-Type" : 'application/json'
            }
        }
        return manageFetch(process.env.MIX_REACT_APP_URL + '/api/address',options)
    }

    const handleSuccessLocation = (location) => {
        geocode(location).then((response) => {
            if (response)
                props.handleChange(props.name, response);
        })
    }

    const handleHafasChange = (value) => {
        props.handleChange(props.name, {name : value});
        clearTimeout(timer)
        const newTimer = setTimeout(async () => {
            let response = await getAddress(JSON.stringify({['from'] : {name : value}}))
            let addresses = Array.from(response) ?? [];
            if (addresses.length)
                addresses = addresses.map((address,idx) => (
                    {
                        id : idx,
                        node :  <CustomItem item={address} />,
                        value : address.name,
                        ...address
                    }
                ))

            setAddresses(addresses)
        }, 300)
        setTimer(newTimer);
    }

    const handleGetGeolocation = () => {
        navigator.geolocation.getCurrentPosition(handleSuccessLocation);
    }

    const myFilterFunction = useCallback(
        (items, value) => items,
        [addresses],
    );

    const CustomItem = ({ item }) => {
        // Each item is wrapped in a li element, so we don't need to provide a custom li element here.
        return (
          <div style={{ display: 'flex', gap: '5px' }}>
            {item.type === 'S' ?
            <img src={'https://carte.noctambus.ch/' + iconBus} width="16px" />
            :
            <img src={'https://carte.noctambus.ch/' + iconAddress} width="16px" />
            }
            <span>{item.name}</span>
          </div>
        );
    };

    return (
        <InputGroup className="mb-3">
            <DatalistInput
                setValue={handleHafasChange}
                placeholder={props.placeholder}
                name={props.name}
                filters={[myFilterFunction]}
                showLabel={false}
                value={props.value}
                label={props.placeholder}
                items={addresses}
                inputProps={{className : 'rounded-pill border-0 form-control form-control-lg input-address'}}
                onSelect={(value) => props.handleChange(props.name, {...value, node: null})}
            />
            <Button onClick={handleGetGeolocation}
                variant="outline-secondary"
                className='btn border-0 btn-sm rounded-pill btn-default  px-2 px-md-1'
                style={{marginLeft : '-42px', zIndex : 10}}
            >
                <BiCurrentLocation className='fs-2'/>
            </Button>
        </InputGroup>
    )
}
export default InputHafasAddress
