import React, {useState,useEffect} from "react"
import {Form, Button, Spinner} from "react-bootstrap"
import { useDispatch,useSelector } from "react-redux"
import {FaArrowRight} from "react-icons/all";

import DepartureThunks from "../../../../features/departure/departure.thunks"
import InputAddress from "../InputAddress"
import InputHafasAddress from "../InputHafasAddress"
import InputDate from "../InputDate"
import "../../journey.css"
import {removeAll, setDirectionMarkers} from "../../../../features/map/map.slice";
import {formToMarkers} from "../../../../utils/googleMap";
import NocButton from "../../../../commons/NocButton";

const FormDeparture = (props) => {

    const dispatch = useDispatch()

    let [form, setForm] = useState({from : {}},{date : {}})
    let btnDisabled =
        Object.keys(form).filter((k) => form[k].crd !== undefined ).length === 1 &&
        props.status !== 'pending'

    const handleChange = (name,value) => setForm({...form,[name]:value})

    const handleChangeDate = (values) => {
        setForm({...form,...values})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(DepartureThunks.post(JSON.stringify(form)))
    }

    useEffect(() => {
        if (form.from.geometry !== undefined )
            dispatch(setDirectionMarkers(formToMarkers(form)))
    },[form]);

    useEffect(() => {
        if (props.tab === 'departure') {
            dispatch(removeAll())
            dispatch(setDirectionMarkers(formToMarkers(form)))
        }
    },[props.tab])

    return  (
            <div className={'form py-5 px-4 '+ props.className }>
                <h3 className="mb-4"><span className='fw-bolder'>Rentrer</span> un arrêt</h3>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="inputDate">
                        <InputDate
                            value='18/07/2022'
                            placeholder='Dép maintenant'
                            name="date"
                            handleChange={handleChangeDate}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inputDeparture">
                        <InputHafasAddress
                            value={(form.from.name) || ''}
                            placeholder='Arrêt'
                            name="from"
                            handleChange={handleChange}
                        />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <NocButton
                            disabled={!btnDisabled}
                            active={btnDisabled}
                            type={'submit'}
                            status={props.status}
                            arrow='right'
                            title={<b>Rechercher</b>}
                        />
                    </div>
                </Form>
            </div>
    )
}

export default FormDeparture
