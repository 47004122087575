import {createSlice, isAnyOf} from '@reduxjs/toolkit';
import LinesThunks  from './lines.thunks'

const initialState = {
    data: [],
    status: 'idle' | 'pending' | 'succeeded' | 'failed',
    error: null
};

export const LinesSlice = createSlice(
    {
        name: 'lines',
        initialState: initialState,
        reducers: {
            setHoveredStop: (state, action) =>  {
                const {lineId, extId} = action.payload;
                let idx = state.data.findIndex(l => +l.id === +lineId);
                state.data[idx].hoveredStop = extId;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(LinesThunks.getAll.fulfilled, (state, action) => {
                    state.data = action.payload.response;
                    state.status = 'succeeded'
                })
                .addCase(LinesThunks.getById.fulfilled, (state, action) => {
                    let line = action.payload.response;
                    let idx = state.data.findIndex(l => +l.id === +line.id);
                    if (idx > -1) state.data[idx] = line;
                    else state.data.push(line);
                    state.status = 'succeeded'
                })
                .addMatcher(isAnyOf(
                    LinesThunks.getAll.pending,
                    LinesThunks.getById.pending
                ), (state, action) => {
                    state.status = 'pending'
                })
                .addMatcher(isAnyOf(
                    LinesThunks.getAll.rejected,
                    LinesThunks.getById.rejected
                ), (state, action) => {
                    state.status = 'failed'
                    state.error = action.error.message
                })
        }
    });

export const {setHoveredStop} = LinesSlice.actions;

export default LinesSlice.reducer;
