import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import SubHeader from "../../commons/SubHeader";
import LinesThunks from "../../features/lines/lines.thunks";
import {setLine} from "../../features/map/map.slice";
import {ButtonGroup, Col, Container, Row, Table, ToggleButton} from "react-bootstrap";
import MapRender from "../../commons/MapRender";
import {setHoveredStop} from "../../features/lines/lines.slice";
import {FaMapMarkedAlt, HiOutlineSwitchVertical, ImPrinter} from "react-icons/all";
import {FaList} from "react-icons/fa";
import NocButton from "../../commons/NocButton";

const Line = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [direction, setDirection] = useState('outbound');
    const [showMap, setShowMap] = useState(false);
    const [day, setDay] = useState(5);

    const [journey, setJourney] = useState(null)

    const line = useSelector(state => state.lines.data.find(line => +line.id === +id))
    const lineStatus = useSelector(state => state.lines.status);

    const handleOnHover= (extId) => {
        const lineId = id;
        dispatch(setHoveredStop({lineId, extId}));
    }

    const handleChangeDirection = () => {
        setDirection(direction => direction === 'outbound' ? 'inbound' : 'outbound');
    }

    useEffect(() => {
        dispatch(LinesThunks.getById(id))
    },[])

    useEffect(() => {
        if (lineStatus ===  "succeeded") {
            dispatch(setLine({line, direction, journey}));
        }
    }, [line, direction, lineStatus, journey])

    let loading = lineStatus !==  "succeeded";

    let rgbaColor = line?.backgroundColor.replace(/rgb/i, 'rgba').replace(/\)/, `,.7)`);
    let styleHover = {
        backgroundColor: rgbaColor,
        color : line?.color,
        fontWeight : 600,
    }

    return (
        <>
            <SubHeader
                loading={loading}
                title={'Ligne '}
                line={line}
                subtitle={
                    !loading ?
                        line[direction]?.stops[0]?.name + ' - ' +
                        line[direction]?.stops[line[direction].stops.length-1]?.name
                    : null
                }
                button={
                    <>
                        <ButtonGroup className='me-0 d-md-none'>
                            {['Vendredi', 'Samedi'].map((title, idx) => (
                                <ToggleButton
                                    style={{height: "fit-content"}}
                                    className={'border border-1 form-control noc-day-button rounded-pill  rounded-'+(idx % 2 ? 'start' : 'end')}
                                    key={idx}
                                    id={`radioh-${idx}`}
                                    type="radio"
                                    variant={'outline-white'}
                                    name="radioh"
                                    checked={day === (+idx+5)}
                                    onChange={() => setDay((idx+5))}
                                >
                                    {title}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                        <div>
                            {!loading && line.inbound.lines.length > 0 &&
                                <NocButton
                                    className='d-md-none ps-0 noc-top-button mx-1'
                                    variant='secondary'
                                    handleClick={handleChangeDirection}
                                    icon={<HiOutlineSwitchVertical className="button-right no-animate ms-0"/>}
                                />
                            }
                           <NocButton
                               handleClick={ () => setShowMap(!showMap)}
                               variant='secondary '
                               className='mx-1 d-md-none ps-0 noc-top-button'
                               icon={
                                   !showMap ? <FaMapMarkedAlt className="button-right no-animate ms-0"/> :
                                              <FaList className="button-right no-animate ms-0"/>
                               }
                           />
                            <NocButton
                                className='ms-1 d-md-none ps-0 noc-top-button'
                                handleClick={() => window.print()}
                                variant='secondary'
                                icon={<ImPrinter className="button-right no-animate ms-0"/>}
                            />
                        </div>
                    </>
                }
            />
            <Container fluid={true} className='lines-container px-0'>
                <Row className='h-100 gx-0 '>
                    <Col className={(showMap && 'd-none') + ' timetable px-0 mt-md-4'}>
                        <Table className='mb-0'>
                            {!loading &&
                                <>
                                    <thead>
                                        <tr>
                                            <td className='border-0 ps-lg-5 noc-secondary-text'>
                                                <small>
                                                    <b>Horaire</b> valable du <b>{line.validity?.start}</b> au <b>{line.validity?.end}</b>
                                                </small>
                                            </td>
                                            {
                                                Array.from(line[direction].lines).filter((lineId) =>
                                                    line[direction].weekdays[lineId].includes(day)).map((id, idx) =>
                                                        <td
                                                            className='border-0 noc-secondary-text align-bottom text-center text-nowrap'
                                                            style={
                                                                journey === id ? styleHover : {}
                                                            }
                                                            onMouseEnter={() => setJourney(id)}
                                                            onMouseLeave={() => setJourney(null)}
                                                        >
                                                            <small>
                                                                {++idx}<sup>e</sup> <span className='d-none d-md-inline-block'>passage</span>
                                                            </small>
                                                        </td>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        line[direction].stops.map(stop => (
                                            <tr className=' border-bottom tr-time' onMouseEnter={() => handleOnHover(stop.extId)}>
                                                <td className='ps-lg-5 border-0 text-nowrap'><b>{stop.name}</b></td>
                                                {
                                                    line[direction].lines
                                                        .filter(id =>
                                                            line[direction].weekdays[id]?.includes(day)
                                                        ).map(id => (
                                                            <td
                                                                className='border-0 text-center text-nowrap'
                                                                style={
                                                                    journey === id && stop.times[id] !== null ? styleHover : {}
                                                                }
                                                                onMouseEnter={() => setJourney(id)}
                                                                onMouseLeave={() => setJourney(null)}
                                                            >
                                                                {
                                                                    stop.times[id]
                                                                }
                                                            </td>
                                                        )
                                                    )
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </>
                            }
                        </Table>
                    </Col>
                    <Col md={4} className={(!showMap && 'd-none') + ' p-md-5  d-md-block div-info line-map d-print-none'} >
                        <div className='sticky-top' >
                            <h3 className='noc-secondary-text mb-4 d-none d-md-block'>Informations complémentaires</h3>
                            <div className='mb-3 d-none d-md-block'>
                                <ButtonGroup className='me-3 my-1 flex-fill'>
                                    {['Vendredi', 'Samedi'].map((title, idx) => (
                                        <ToggleButton
                                            className={'border-0 form-control noc-day-button rounded-pill rounded-'+(idx % 2 ? 'start' : 'end')}
                                            key={idx}
                                            id={`radio-${idx}`}
                                            type="radio"
                                            variant={'outline-white'}
                                            name="radio"
                                            checked={day === (+idx+5)}
                                            onChange={() => setDay((idx+5))}
                                        >
                                            {title}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>

                                {!loading && line.inbound.lines.length > 0 &&
                                    <NocButton
                                        className='me-3 my-1'
                                        variant='secondary'
                                        handleClick={handleChangeDirection}
                                        icon={<HiOutlineSwitchVertical className="button-right"/>}
                                        title={<span className='ms-1'><b>Sens inverse</b></span>}
                                    />
                                }
                                <NocButton
                                    className='my-1'
                                    handleClick={() => window.print()}
                                    variant='secondary'
                                    icon={<ImPrinter className="button-right"/>}
                                    title={<b>Imprimer</b>}
                                />
                            </div>
                            <MapRender/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Line
