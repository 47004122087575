export const formToMarkers = (form) => Object.keys(form)?.map((v,idx) => {
    if (form[v].geometry !== undefined) {
        let type = v === 'from' ? 'start' : 'end'
        let geometry = JSON.parse(JSON.stringify(form[v].geometry))
        return {
            idx: idx,
            type: type,
            [type + '_location']: {
                lat: geometry.location.lat,
                lng: geometry.location.lng,
            }
        }
    } else return null
}).filter(marker => marker !== null)

export const directionResultMarker = (step, v) => {
    let type = v === 'from' ? 'start' : 'end'
    return {
        idx: step.extId,
        type: type,
        [type + '_location']: {
            lat: step[v].crd.y / 1000000,
            lng: step[v].crd.x / 1000000,
        }
    }
}

export const createBounds = (paths) => {
    const bounds = new window.google.maps.LatLngBounds();
    (paths ?? []).map((path) => {
        google.maps.geometry.encoding.decodePath(path).map((p) => bounds.extend(p));
    })
    return bounds;
};

export const styles = [
    {
        featureType: "poi",
        elementType: "labels",
        stylers: [{visibility: "off"}]
    },
    {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#47A7E3"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "color": "#808080"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b3b3b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "weight": 1.8
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
]
