import React, {useEffect} from 'react';
import {Route, Routes, useLocation,} from "react-router-dom";
import Journey from './pages/journey/Journey';
import Lines from './pages/lines/Lines';
import Line from './pages/lines/Line';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useJsApiLoader} from "@react-google-maps/api";

function App(props) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.MIX_GOOGLE_MAPS_API_KEY,
        libraries : ['geometry','places']
    })

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }, [pathname]);
    return (
        <div className="App">
            {pathname === '/' && isLoaded ?
                <Journey iframe={true}/>
            :
                <>
                    {isLoaded &&
                        <>
                            <Routes>
                                <Route path="/itinerary" element={<Journey />}/>
                                <Route path="/lines" exact element={<Lines/>}/>
                                <Route path="/lines/:id" exact element={<Line/>}/>
                            </Routes>
                        </>
                    }
                </>
            }
        </div>
    );
}

export default App;
