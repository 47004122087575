import React from "react"
import {Link} from "react-router-dom"
import LineBadge from "./LineBadge"

const LineLink = (line) => {
    return (
        <Link
            to={'/lines/'+line.id}
            className=" mx-2">
            <LineBadge {...line}/>
        </Link>
    )
}

export default LineLink
