import { createSlice } from '@reduxjs/toolkit';
import {createBounds} from "../../utils/googleMap";

const initialState = {directionMarkers: [],
                      directionResult : null,
                      departureResult : null,
                      line : null,
                      journey : null,
                      lineDirection : 'go',
                      bounds : null,
};

export const MapSlice = createSlice(
    {
        name: 'map',
        initialState: initialState,
        reducers: {
            setDirectionMarkers : (state, action) =>  {
                state.directionMarkers = action.payload;
                if (action.payload.length) {
                    const bounds = new google.maps.LatLngBounds();
                    action.payload.map((marker) => {
                        bounds.extend(marker.start_location || marker.end_location);
                    })
                    state.bounds = JSON.parse(JSON.stringify(bounds));
                }
            },
            setLine : (state, action)  => {
                state.line = action.payload.line;
                state.lineDirection = action.payload.direction;
                state.journey = action.payload.journey;
                let paths = action.payload.line[action.payload.direction].paths;
                state.bounds = JSON.parse(
                    state.journey != null
                        ? JSON.stringify(createBounds([paths[state.journey]]))
                        : JSON.stringify(createBounds(Object.keys(paths).map(k => paths[k])))
                )

            },
            setDepartureResult : (state, action)  => {
                state.departureResult = action.payload;
                if (action.payload !== null) {
                    const bounds = new google.maps.LatLngBounds();
                    action.payload.departures.map(departure =>
                        bounds.extend(departure.crd)
                    )
                    state.bounds = JSON.parse(JSON.stringify(bounds));
                }
            },
            setDirectionResult : (state, action)  => {
                state.directionResult = action.payload;
                if (action.payload !== null)
                    state.bounds =  JSON.parse(
                        JSON.stringify(
                            createBounds(action.payload.steps.map(step => step.path))))

            },
            setBounds : (state, action) => {
                state.bounds = createBounds(action.payload)
            },
            removeAll : () =>  {
                return initialState;
            },
        },
    });

export const {setDirectionMarkers, setDirectionResult,setLine,removeAll,setBounds,setDepartureResult} = MapSlice.actions;

export default MapSlice.reducer;
