import { createSlice } from '@reduxjs/toolkit';
import DepartureThunks from "./departure.thunks";

const initialState = {result: null, status: 'idle', error: ''};

export const DepartureSlice = createSlice(
    {
        name: 'departure',
        initialState: initialState,
        reducers: {
            setDeparture : (state, action) => {
                state.result = action.payload;
            },
            removeDeparture : (state, action) => {
                state.result = null;
            }
        },
        extraReducers(builder) {
            builder
                .addCase(DepartureThunks.post.pending, (state, action) => {
                    state.status = 'pending'
                })
                .addCase(DepartureThunks.post.fulfilled, (state, action) => {
                    state.status = 'succeeded'
                    state.result = action.payload.response;
                })
                .addCase(DepartureThunks.post.rejected, (state, action) => {
                    state.status = 'failed'
                    state.result = null
                    state.error = action.error.message
                })
        }
    });

export const {setDeparture, removeDeparture} = DepartureSlice.actions;

export default DepartureSlice.reducer;
