import { manageError, manageFetch } from "../../utils/utils";
import {createAsyncThunk} from "@reduxjs/toolkit";


export async function request(method, url, body = null) {
    const options = {
        method: method,
        body: body,
        headers : {
            "Content-Type" : 'application/json'
        }
    }

   return manageFetch(url, options)
}

const DepartureThunks = {
    post : createAsyncThunk('departure/post',
        async (body, thunkAPI)=> {
            const url = process.env.MIX_REACT_APP_URL + "/api/departure";
            const response = await request('POST', url, body);
            return {response};
        }),
}
export default DepartureThunks;
