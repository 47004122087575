import React from "react"
import LineBadge from "./LineBadge";
import {Dropdown} from "react-bootstrap";


const LineDropdownItem = (props) => (
        <div className='w-100 d-flex justify-content-between'>
            <span  className='ellipsis w-75'>
                  {
                      props.line['outbound'].stops[0]?.name +' - '+
                      props.line['outbound'].stops[props.line['outbound'].stops.length-1]?.name
                  }
            </span>
            <LineBadge {...props.line}  fontSize={'11px'}/>
        </div>
)


export default LineDropdownItem;
