import React from "react";
import {Polyline} from "@react-google-maps/api";

const LinePolyline = (props) => {

    const options = {
        strokeWeight: props.border ? 10 : 7,
        strokeColor: props.border ? props.line.color : props.line.backgroundColor,
        strokeOpacity: 1,
        zIndex: -1,
    }

    return <Polyline
                key={props.idx}
                path={google.maps.geometry.encoding.decodePath(props.path)}
                options={options}
            />
}

export default LinePolyline
