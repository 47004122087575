import React from "react";
import {Marker} from "@react-google-maps/api"

const DirectionMarker = (props) => {
    return <Marker
        key={'marker_'+props.type}
        icon={{
            url : "/img/location_"+props.type+".svg",
            scaledSize: new google.maps.Size(50, 50)
        }}
        zIndex={999}
        position={props.type === 'start' ? props.start_location : props.end_location}
    />
}
export default DirectionMarker;
