import React, {useEffect, useRef, useState} from "react"
import {Col, Container, Row} from "react-bootstrap"
import {FiArrowDown} from "react-icons/all";
import MapRender from "../../commons/MapRender"
import LinesList from "./components/LinesList"
import {useDispatch, useSelector} from "react-redux";
import LinesThunks from "../../features/lines/lines.thunks";
import './lines.css'
import NocButton from "../../commons/NocButton";
import LineDropdownItem from "./components/LineDropdownItem"
import {setLine} from "../../features/map/map.slice";
import {Link} from "react-router-dom";
import {Typeahead} from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

const Lines = () => {

    const dispatch = useDispatch();
    const lines = useSelector(state => state.lines.data)
    let [lineLink, setLineLink] = useState(null)
    const ref = useRef();
    const handleSelectedLine = (line) => {
        if (line.length) {
            let line_id = line[0].id;
            let state = {line: lines.find(line => +line.id === +line_id), direction: 'outbound'};
            dispatch(setLine(state));
            setLineLink('/lines/' + line_id)
        }
    }

    useEffect(() => {
        if (lines.length < 2)
            dispatch(LinesThunks.getAll());
    })

    return  (
        <>
            <Container fluid='lg' className='mt-5 mb-2 '>
                <Row >
                    <Col md={5} lg={5} >
                        <h2 className="mb-4">Nos lignes</h2>
                        <p>
                            Les Noctambus sont répartis en deux catégories de lignes:
                            <br />
                            <ul className="dash">
                                <li>les lignes régionales, qui ont des parcours particuliers au départ du centre et à destination des communes périphériques;</li>
                                <li>les lignes urbaines, qui sont des lignes tpg normales opérées durant des horaires étendus le week-end.</li>
                            </ul>

                        </p>
                    </Col>
                    <Col  md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}  >
                        <>
                            <LinesList
                                lines={lines.filter(line => line.area === "regional")}
                                title='Lignes Régionales'
                                key={'list_1'}
                            />
                            <LinesList
                                lines={lines.filter(line => line.area === "urban")}
                                title='Lignes Urbaines'
                                key={'list_2'}
                            />
                        </>
                    </Col>
                </Row>
            </Container>
            <div className='section-secondary'>
                <Container fluid='lg' className=' pt-5 pb-0 pb-md-5 '>
                    <Row >
                        <Col lg={5} >
                            <h2 className="mb-4">Trouve ta ligne</h2>
                            <div>
                                <div>
                                    <Typeahead
                                        emptyLabel="Aucun résultat"
                                        id="basic-typeahead-single"
                                        labelKey={(line) =>
                                            line['outbound'].stops[0].name + ' - '+
                                            line['outbound'].stops[line['outbound'].stops.length-1].name + ' '+
                                            line.number
                                        }
                                        ref={ref}
                                        clearButton
                                        options={lines}
                                        onChange={(line) => handleSelectedLine(line)}
                                        renderInput={(input) => {
                                            return (
                                                <>
                                                    <input type='text' {...input}
                                                        style={{minHeight : 'calc(1.5em + 1rem + 0px)'}}
                                                        className='rbt-input-main form-control rbt-input rounded-pill border-0 form-control form-control-lg input-address'
                                                    />
                                                    <FiArrowDown
                                                        onClick={() => ref.current?.toggleMenu()}
                                                        style={{cursor : 'pointer'}}
                                                        className="button-right no-animate"/>
                                                </>
                                            )
                                        }}
                                        placeholder="Sélectionne ta ligne"
                                        renderMenuItemChildren={(option) => {
                                            return (
                                                <LineDropdownItem
                                                    line={option}
                                                    idx={option.id}
                                                    key={'line_'+option.id}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <Link to={lineLink !== null ? lineLink : ''} className='w-100'>
                                    <NocButton
                                        className='mb-4 w-100 btn-secondary  mt-4'
                                        arrow='right'
                                        title='Consulte les horaires'
                                        disabled={lineLink === null}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col   lg={{ span: 6, offset: 1 }} className="p-0 lines-map">
                            <MapRender />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Lines
