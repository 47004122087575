import React, {useState,useEffect} from "react"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import {FaRegClock, FaRoute} from "react-icons/fa";
import {removeAll, setDepartureResult, setDirectionMarkers} from "../../features/map/map.slice";
import {useDispatch} from "react-redux";
import { useJsApiLoader } from '@react-google-maps/api';
import SubHeader from "../../commons/SubHeader"
import Departures from "./components/departure/Departures";
import Direction from "./components/direction/Direction"
import MapRender from "../../commons/MapRender"

import './journey.css'

import {removeDeparture, setDeparture} from "../../features/departure/departure.slice";
import {removeDirection, setDirection} from "../../features/direction/direction.slice";
import {formToMarkers} from "../../utils/googleMap";

const Journey = (props) => {
    const dispatch = useDispatch()

    const initTab = localStorage.getItem('init_departure') !== null ? 'departure' : 'direction';
    const [tab, setTab] = useState(initTab);
    const [showMap, setShowMap] = useState(false);
    const isIframe = props.iframe === true;

    const handleChangeTab =(tab) => {
        setShowMap(false);
        setTab(tab)
    }

    const handleShowMap = (showMap) => {
        setShowMap(showMap);
    }

    useEffect(() => {
        if (localStorage.getItem('init_departure') !== null) {
            let data = JSON.parse(localStorage.getItem('init_departure'));
            localStorage.removeItem('init_departure');
            dispatch(setDeparture(data.result))
            dispatch(setDirectionMarkers(data.markers))
        }else if (localStorage.getItem('init_direction') !== null) {
            let data = JSON.parse(localStorage.getItem('init_direction'));
            localStorage.removeItem('init_direction');
            dispatch(setDirection(data.result))
            dispatch(setDirectionMarkers(data.markers))
        }else {
            dispatch(removeDirection())
            dispatch(removeDeparture())
            dispatch(removeAll())
        }
    },[])

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xl={isIframe ? 12 : 3 } md={isIframe ? 12 : 4 } className="p-0">
                        <Tabs fill
                            activeKey={tab}
                            id="tab-journey"
                            onSelect={handleChangeTab}
                            >
                            <Tab eventKey="direction" className='' title={
                                <>
                                    <FaRoute className="btnIcon" />
                                    <h4>Itinéraire</h4>
                                </>
                            }>
                                <Direction tab={tab} handleShowMap={handleShowMap} isIframe={isIframe} showMap={showMap}/>
                            </Tab>
                            <Tab eventKey="departure"  title={
                                <span className='ellipsis'>
                                    <FaRegClock className="btnIcon" />
                                    <h4>Prochain départ</h4>
                                </span>
                            }>
                                <Departures tab={tab} handleShowMap={handleShowMap} isIframe={isIframe} showMap={showMap}/>
                            </Tab>
                        </Tabs>
                    </Col>
                    {!isIframe &&
                        <Col md={8} xl={9} className={(!showMap && 'd-none') + ' px-0  d-md-block '}>
                            <MapRender/>
                        </Col>
                    }
                </Row>
            </Container>
        </>
    )
}

export default Journey
