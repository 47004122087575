import React from "react";
import {FaWalking} from "react-icons/fa";
import {InfoWindow as DefaultInfoWindow} from "@react-google-maps/api";

const InfoWindow = (props) => {
    let path = google.maps.geometry.encoding.decodePath(props.path);
    return <DefaultInfoWindow
        key={props.idx}
        position={
            path[(path.length / 2) | 0]
        }
        className={'custom-info-window'}
    >
        <div>
            {props.type === 'JNY' ?
                <>
                    <div className='transit-infowindow' style={{
                        color:props.color,
                        backgroundColor : props.backgroundColor
                    }}>{props.number}</div>
                </>
                :
                <FaWalking/>
            }
        </div>
    </DefaultInfoWindow>
}
export default InfoWindow
