import React, {useState,useEffect} from "react";
import {Marker} from "@react-google-maps/api"
import {InfoWindow} from "@react-google-maps/api";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
const LineMarker = (props) => {
    const latLng = props.crd;
    const hoveredStop = +props.line.hoveredStop === +props.extId ;
    const [showInfoWindow, setShowInfoWindow] = useState(hoveredStop);

    useEffect(() => {
        setShowInfoWindow(hoveredStop)
    },[hoveredStop])

    return <Marker
        key={'stop_marker_'+props.idx}
        icon={{
            path: google.maps.SymbolPath.CIRCLE	,
            fillColor:  !hoveredStop ? 'white' : props.line.backgroundColor ,
            fillOpacity: 1,
            scale: hoveredStop ? 7 : 5,
            strokeColor:"black",
            strokeWeight: 2,
        }}
        title={props.name}
        onClick={() => setShowInfoWindow(!showInfoWindow)}
        position={latLng}
    >
        {showInfoWindow && (
            <InfoWindow
                    position={latLng}
                >
                <div>
                    {props.name}<br />
                </div>
            </InfoWindow>
        )}
    </Marker>
}
export default LineMarker;
