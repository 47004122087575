import React,{useState,useEffect} from "react"
import {Container, Row, Col} from "react-bootstrap"
import {useSelector, useDispatch} from "react-redux"
import {FaMapMarkerAlt, FaArrowLeft, FaMap, FaList} from "react-icons/fa"

import FormDeparture from "./FormDeparture"
import Departure from "./Departure"
import {removeDeparture, setDeparture} from "../../../../features/departure/departure.slice"
import {FaMapMarkedAlt} from "react-icons/all";
import {setDepartureResult} from "../../../../features/map/map.slice";

const Departures = (props) => {
    const dispatch = useDispatch()
    const {result, status} = useSelector(state => state.departure)
    const directionMarkers = useSelector(state => state.map.directionMarkers);
    const [page, setPage] = useState(0)

    const handleBack = () => {
        setPage(0)
        props.handleShowMap(false);
        dispatch(removeDeparture())
    }

    const handleSetPage = (inc) => {
        setPage(page+inc)
    }

    useEffect(() => {
        if (props.isIframe && result !== null) {
            localStorage.setItem('init_departure', JSON.stringify({result : result, markers : directionMarkers}));
            window.top.location.replace(process.env.MIX_REACT_APP_REDIRECT);
        } else dispatch(setDepartureResult(result));
    },[result])

    useEffect(() => {
        if (props.tab === 'departure')
            dispatch(setDepartureResult(result))
    },[props.tab])

    return  (
            <>
                <FormDeparture isIframe={props.isIframe} tab={props.tab} className={(result !== null && !props.isIframe) && 'd-none'}  status={status} />
                { result !== null && !props.isIframe &&
                    <>
                        <Container fluid='lg' className="return">
                            <Row>
                                <Col xs={2}>
                                    <button onClick={handleBack}
                                            className='btn btn-sm btn-default  d-flex text-white'>
                                        <FaArrowLeft/>
                                    </button>
                                </Col>
                                <Col xs={8} className='ps-4 d-flex align-items-center'>
                                    <h5 className="text-center mb-0">Résultats</h5>
                                </Col>
                                <Col xs={2} className='ps-4 d-flex align-items-center d-md-none'>
                                    <button onClick={ () => props.handleShowMap(!props.showMap)}
                                            className='btn btn-sm btn-default  d-flex text-white'>
                                        {!props.showMap ? <FaMapMarkedAlt/> : <FaList/> }
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid='lg' className={(props.showMap && 'd-none') + ' itinerary py-4'}>
                            <Row>
                                <Col>
                                    <h4>Prochain départ</h4>
                                </Col>
                            </Row>
                            <Row className="border-bottom border-3 py-3">
                                <Col xs={3} lg={3}>
                                    <b>Depuis</b>
                                </Col>
                                <Col >
                                    <FaMapMarkerAlt/> {result.from}
                                </Col>
                            </Row>
                            {
                                result.departures.slice(page, page + 5).map((dep, idx) =>
                                    <Departure {...dep} key={'departure_' + idx}/>
                                )
                            }
                            <Row className="border-top border-3 py-3">
                                <Col xs={6}>
                                    {(page >= 5) &&
                                        <a className="link" onClick={() => handleSetPage(-5)}><b>Départs précédents</b></a>
                                    }
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                    {(result.departures.length - 1 > page + 5) &&
                                        <a className="link" onClick={() => handleSetPage(5)}><b>Départs suivants</b></a>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </>
                }
         </>
    )
}

export default Departures
