import React, {useRef, useState} from "react";
import { Autocomplete } from '@react-google-maps/api';
import {Button, InputGroup, Form} from "react-bootstrap";
import {BiCurrentLocation} from "react-icons/all";
import {geocode} from "../../../utils/utils";


const InputAddress = (props) => {

    const inputRef = useRef(null);

    const handleSuccessLocation = (location) => {
        geocode(location).then((response) => {
            if (response)
                props.handleChange(props.name, response);
        })
    }

    const handleGetGeolocation = () => {
        navigator.geolocation.getCurrentPosition(handleSuccessLocation);
    }

    let boundaries = [
        {lng :6.4022827,lat :46.4596195},
        {lng :6.4009094,lat :46.4596195},
        {lng :6.394043 ,lat :46.4671871},
        {lng :6.2594604,lat :46.535248 },
        {lng :6.153717 ,lat :46.5068996},
        {lng :6.0809326,lat :46.414192 },
        {lng :5.84198  ,lat :46.2254529},
        {lng :5.7019043,lat :46.1503458},
        {lng :5.667572 ,lat :46.0551263},
        {lng :5.6442261,lat :45.8594121},
        {lng :5.7170105,lat :45.8565429},
        {lng :5.8035278,lat :45.8450644},
        {lng :5.8158875,lat :45.8594121},
        {lng :5.8378601,lat :45.9186766},
        {lng :5.8612061,lat :46.0293888},
        {lng :5.9243774,lat :46.0274819},
        {lng :6.0562134,lat :46.0160387},
        {lng :6.2471008,lat :46.0284353},
        {lng :6.3102722,lat :46.0408292},
        {lng :6.3775635,lat :46.037016},
        {lng :6.4530945,lat :46.0255748},
        {lng :6.4682007,lat :46.0122238},
        {lng :6.4833069,lat :45.96547},
        {lng :6.5327454,lat :45.9205873},
        {lng :6.587677 ,lat :45.954014},
        {lng :6.6027832,lat :46.0026854},
        {lng :6.6453552,lat :46.061797},
        {lng :6.6165161,lat :46.1132297},
        {lng :6.5313721,lat :46.1379765},
        {lng :6.4393616,lat :46.1446372},
        {lng :6.3967896,lat :46.1779286},
        {lng :6.4091492,lat :46.2197521},
        {lng :6.5533447,lat :46.2938156},
        {lng :6.5107727,lat :46.4037762},
    ];

    const bounds = new google.maps.LatLngBounds();
    boundaries.map(bound =>
        bounds.extend(bound)
    )

    return (
        <Autocomplete
            ref={inputRef}
            placeholder={props.placeholder}
            options={{
                bounds : bounds,
                componentRestrictions : {
                    country : ['ch','fr'],
                },
                options : {
                    bounds : bounds,
                    strictBounds : true
                },
            }}
            onPlaceChanged={(e) => {
                props.handleChange(props.name, inputRef.current.state.autocomplete.getPlace());
            }}
        >
            <InputGroup className="mb-3">
                <Form.Control
                    onChange={ (e) => { props.handleChange(props.name, {  'name': e.target.value})}}
                    value={props.value}
                    name={props.name}
                    className='rounded-pill border-0 form-control form-control-lg input-address'
                    type="text"
                    placeholder={props.placeholder}
                ></Form.Control>
                <Button onClick={handleGetGeolocation}
                        variant="outline-secondary"
                        className='btn border-0 btn-sm rounded-pill btn-default  px-2 px-md-1'
                        style={{marginLeft : '-42px', zIndex : 10}}
                >
                    <BiCurrentLocation className='fs-2'/>
                </Button>
            </InputGroup>
        </Autocomplete>
    )
}
export default InputAddress
