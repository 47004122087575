import React,{useEffect,useState} from "react";
import { Container,Placeholder } from "react-bootstrap";
import './layouts.css'
import LineBadge from '../pages/lines/components/LineBadge';

const SubHeader = (props) => {

    const [offset, setOffset] = useState(0);
    const [toggleHeader, setToggleHeader] = useState(false);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return  <div className={"subheader sticky-top  "} >
                {props.loading ?
                    <Container fluid={true} className='px-2 px-lg-5'>
                        <Placeholder  as="h1" animation="wave">
                            <Placeholder bg="light" md={2} xs={6} />
                        </Placeholder>
                        <Placeholder  as="h2"  animation="wave"  className='mb-0'>
                            <Placeholder  bg="light" md={5} xs={12} />
                        </Placeholder>
                    </Container>
                    :
                    <Container fluid={true} className='px-lg-5 w-100 align-items-center'>
                        <h2>{props.title} {props.line && <LineBadge {...props.line}/>} </h2>
                        <h3 className='mb-0 ellipsis noc-secondary-text'>
                            {props.subtitle}
                        </h3>
                        <div className='d-flex mb-1 d-print-none w-100 align-content-end justify-content-between'>{props.button}</div>
                    </Container>
                }
            </div>
}
export default SubHeader;
