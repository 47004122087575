import { configureStore } from '@reduxjs/toolkit';
import directionReducer from './direction/direction.slice';
import linesReducer from './lines/lines.slice';
import departureReducer from './departure/departure.slice';
import mapReducer from './map/map.slice';

export const store = configureStore({
    reducer: {
        direction: directionReducer,
        departure: departureReducer,
        lines : linesReducer,
        map : mapReducer
    },
});
