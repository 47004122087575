import React,{useState} from "react"
import {Row, Col} from "react-bootstrap"
import {FaChevronRight, FaWalking} from "react-icons/fa"

import LineBadge from "../../../lines/components/LineBadge"

const Step = (step) => {

    const style =
        step.type === 'JNY' ?
            {
                borderLeftColor : step.backgroundColor,
                borderLeftWidth : '4px',
                borderLeftStyle : 'solid',
                boxShadow: '-2px 0px 0px 0px '+step.backgroundColor + ' , '+
                    'inset 2px 0px 0px 0px'+ step.backgroundColor
            } : {
                borderLeftColor :  step.backgroundColor,
                borderLeftWidth : '4px',
                borderLeftStyle : 'dotted',
            }

    return  (
        step.type === "JNY" ? (
            <>
                <Row className="border-bottom border-left-3 py-3"  style={style}>
                    <Col xs={4}>
                        <b>{step.start}</b>
                    </Col>
                    <Col xs={8}>
                        <b>{step.from.name}</b>
                    </Col>
                </Row>
                <Row className="border-bottom py-3" style={style}>
                    <Col xs={2}>
                        <img src={""} height='15'/>
                    </Col>
                    <Col xs={2} className={'pe-0 me-0 text-end d-flex align-items-center'}>
                        <LineBadge
                            number={step.number}
                            backgroundColor={step.backgroundColor}
                            color={step.color}
                            fontSize={"14"}
                        />
                        <FaChevronRight />
                    </Col>
                    <Col xs={6}>
                        Direction {step.direction}
                    </Col>
                </Row>
                <Row className="border-bottom py-3"  style={style}>
                    <Col xs={4}>
                        <b>{step.end}</b>
                    </Col>
                    <Col xs={8}>
                        <b>{step.to.name}</b>
                    </Col>
                </Row>
            </>
        )
        :
        (
            <Row className="border-bottom py-3" style={style}>
                <Col xs={4}>
                    <FaWalking />
                </Col>
                <Col xs={8}>
                    Marcher {step.duration} minutes ({step.length}m)
                </Col>
            </Row>
        )
    )
}

export default Step
