import { createSlice } from '@reduxjs/toolkit';
import LinesThunks from "../lines/lines.thunks";
import DirectionThunks from "./direction.thunks";

const initialState = {
    result: null,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed',
    error: null
};

export const DirectionSlice = createSlice(
    {
        name: 'direction',
        initialState: initialState,
        reducers: {
            setDirection : (state, action) => {
                state.result = action.payload;
            },
            removeDirection : (state, action) => {
                state.result = null;
            }
        },
        extraReducers(builder) {
            builder
                .addCase(DirectionThunks.post.pending, (state, action) => {
                    state.status = 'pending'
                })
                .addCase(DirectionThunks.post.fulfilled, (state, action) => {
                    state.status = 'succeeded'
                    state.result = action.payload.response;
                })
                .addCase(DirectionThunks.post.rejected, (state, action) => {
                    state.status = 'failed'
                    state.result = null
                    state.error = action.error.message
                })
        }
    });

export const {setDirection, removeDirection} = DirectionSlice.actions;

export default DirectionSlice.reducer;
