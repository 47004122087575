import React from "react"

const LineBadge = (line) => {
    return (
        <span style={{backgroundColor:line.backgroundColor, color:line.color, fontSize:line.fontSize}}
            className={"badge rounded-pill line-badge "+line.className}>{line.number}
        </span>
    )
}

export default LineBadge
