import { manageError, manageFetch } from "../../utils/utils";
import {createAsyncThunk} from "@reduxjs/toolkit";


export async function request(method, body = null) {
    const options = {
        method: method,
        body: body,
        headers : {
            "Content-Type" : 'application/json'
        }
    }
    const url = process.env.MIX_REACT_APP_URL + "/api/direction";

   return manageFetch(url, options)
}

const DirectionThunks = {
    post : createAsyncThunk('direction/post',
        async (body, thunkAPI)=> {
            const response = await request('POST', body);
            return {response};
        }),
}
export default DirectionThunks;
