import React, {useState,useEffect} from "react";
import {Marker} from "@react-google-maps/api"
import {InfoWindow} from "@react-google-maps/api";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
const DepartureMarker = (props) => {
    const latLng = props.crd;
    const [showInfoWindow, setShowInfoWindow] = useState(true);
    console.log(props)

    return <Marker
        key={'stop_marker_'+props.idx}
        icon={{
            path: google.maps.SymbolPath.CIRCLE	,
            fillColor:  "#FFED00" ,
            fillOpacity: 1,
            scale: 7,
            strokeColor:"black",
            strokeWeight: 2,
        }}
        title={props.name}
        onClick={() => setShowInfoWindow(!showInfoWindow)}
        position={latLng}
    >
        {showInfoWindow && (
            <InfoWindow
                className='info-window-marker'
                 style={{marginTop : "-10px"}}
                    position={latLng}
                >
                <div>
                    {props.stop.name}
                    <div style={{textAlign : 'center'}}>
                        {props.lines.map(line  => (
                            <span className='badge rounded-pill line-badge'
                                  style={{backgroundColor : line.backgroundColor, color : line.color}}>
                                {line.name}
                            </span>
                        ))}
                    </div>
                </div>
            </InfoWindow>
        )}
    </Marker>
}
export default DepartureMarker;
