import React,{useState} from "react"
import {Row, Col} from "react-bootstrap"
import {FaChevronRight, FaWalking} from "react-icons/fa"
import Moment from 'moment';

import LineBadge from "../../../lines/components/LineBadge"
import {setDirectionMarkers} from "../../../../features/map/map.slice";
import {formToMarkers} from "../../../../utils/googleMap";
import {useDispatch} from "react-redux";

const Departure = (departure) => {

    const dispatch = useDispatch()

    return  (
        <>
            <Row className="border-bottom py-2 align-items-center">
                <Col xs={3} lg={4} >
                    <b>{departure.time_s}</b>
                    <br/>
                    <small>Dép: {departure.stop.name}</small>
                </Col>
                <Col xs={2}  className={'pe-0 me-0 text-end d-flex align-items-center'}>
                    <LineBadge
                        number={departure.name}
                        backgroundColor={departure.backgroundColor}
                        color={departure.color}
                        fontSize={"1em"}
                    />
                </Col>
                <Col xs={7} lg={6} className=''>
                    {departure.direction}
                </Col>
            </Row>
        </>
    )
}

export default Departure
