import React from "react"

import LineLink from "./LineLink"
import {Placeholder} from "react-bootstrap";

const LinesList = ({title, lines}) => {
    return  <>
                <h3 className="mt-1">{title}</h3>
                <div className="text-start mt-4 mb-5">
                    {lines.length > 0 ?
                        lines.map((line, idx) =>
                            <LineLink
                                {...line}
                                key={'line_'+idx}
                                className='my-2 zoom-on-hover'
                                fontSize={'20px'}
                            />)
                        :
                        <Placeholder  animation="wave"  className='mb-0'>
                            <Placeholder  bg="light" md={12} xs={12} />
                        </Placeholder>
                    }
                </div>
            </>
}

export default LinesList
