import React, {useCallback, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import { GoogleMap
} from '@react-google-maps/api';
import {directionResultMarker, styles} from "../utils/googleMap";
import TransitPolyline from './map_components/TransitPolyline'
import InfoWindow from './map_components/InfoWindow'
import DirectionMarker from "./map_components/DirectionMarker";
import StopMarker from "./map_components/StopMarker";
import LineMarker from "./map_components/LineMarker";
import LinePolyline from "./map_components/LinePolyline";
import {removeAll} from '../features/map/map.slice';
import DepartureMarker from "./map_components/DepartureMarker";

const containerStyle = {
    width: '100%',
    height: '100%',
};

const center = {
    lat: 46.2043907,
    lng: 6.1431577
};

const MapRender = (props) => {
    const dispatch = useDispatch();
    const {
           bounds,
           directionResult,
           directionMarkers,
           line,
           journey,
           departureResult,
           lineDirection
    }= useSelector(state => state.map)

    const [map, setMap] = useState(null)

    const onLoad = useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        dispatch(removeAll())
        setMap(null)
    }, [])

    useEffect(() => {
        if (map !== null && bounds !== null) {
            map.fitBounds(bounds)
        }
    },)

    return (
        <div className='map-render'>
            <GoogleMap
                id='map'
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
                options={{
                    styles : styles
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}
                >
                {
                    directionResult === null && directionMarkers.map((directionMarker, idx) =>
                        <DirectionMarker {...directionMarker} idx={'marker_'+idx}/>
                    )
                }
                {
                    departureResult !== null &&
                        <>
                            {
                                [...new Map(departureResult.departures.map(item =>
                                    [item['stop']['extId'], item])).values()]
                                    .map((departure, idx) =>
                                        ({...departure,
                                            lines :
                                                [...new Map(
                                                    departureResult.departures.filter(d =>
                                                        d.stop.extId === departure.stop.extId
                                                ).map(item =>
                                                    [item['name'], item])).values()]
                                        })
                                    )
                                    .map((departure,idx) =>
                                    <DepartureMarker {...departure} idx={idx} />
                                )
                            }
                        </>
                }
                {
                    directionResult !== null &&
                        <>
                            <DirectionMarker {...directionResultMarker(directionResult.steps[0], 'from')} idx={'marker_'+0}/>
                            <DirectionMarker {...directionResultMarker(directionResult.steps[directionResult.steps.length - 1], 'to')} idx={'marker_'+1}/>
                            {directionResult.steps.map((step, idx) => (
                                <>
                                    <TransitPolyline border={true} {...step} idx={idx+'_2'}/>
                                    <TransitPolyline {...step} idx={idx}/>
                                    <InfoWindow {...step} idx={idx}/>
                                </>
                            ))}
                        </>
                }
                {    (line !== null) &&
                        <>
                            {(journey ?
                                [line[lineDirection].paths[journey]]
                                : Object.keys(line[lineDirection].paths).map(k =>
                                     line[lineDirection].paths[k]
                                )
                            ).map(path =>
                                <>
                                    <LinePolyline border={true} path={path} line={line}   />
                                    <LinePolyline path={path} line={line}  />
                                </>
                            )}
                            {
                                line[lineDirection].stops.map((stop,idx) => (
                                    ((journey && stop.times[journey] !== null) || journey === null) &&
                                        <>
                                            <LineMarker {...stop} idx={idx} line={line}/>
                                        </>
                                ))
                            }
                            <DirectionMarker start_location={line[lineDirection].stops[0]?.crd} type='start' />
                            <DirectionMarker end_location={line[lineDirection].stops[line[lineDirection].stops.length-1]?.crd} type='end'/>
                        </>
                }
            </GoogleMap>
        </div>
    )
}
export default MapRender
